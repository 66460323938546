import React from "react";
import axios from "axios";

function serviceTypeLaboratory() {
  const [typeLaboratory, setTypeLaboratory] = React.useState([]);
  const [error, setError] = React.useState(null);
  const [loading, setLoading] = React.useState(true);
  const [idCategory, setIdCaregory] = React.useState("");

  React.useEffect(() => {
    // Solo ejecuta si idCategory no está vacío
    if (idCategory !== "") {
      const getData = async () => {
        try {
          const url = `/api/v2/type_laboratory_tests?id_category=${idCategory}`;
          const response = await axios.get(url);
          if (response.status === 200) {
            const data = response.data;
            setTypeLaboratory(data);
          } else {
            throw new Error("Network response was not ok.");
          }
        } catch (error) {
          setError(true);
        } finally {
          setLoading(false);
        }
      };
      getData();
    }

    // Limpieza del efecto
    return () => {
      setError(null);
    };
  }, [idCategory]);

  return {
    loading,
    error,
    typeLaboratory,
    idCategory,
    setIdCaregory,
  };
}

export { serviceTypeLaboratory };