import React, { useContext } from "react";
import axios from "axios";
import { CategoryLaboratoryContext } from "../CategoryLaboratoryContext";
import { event } from "jquery";
import { redirect } from "react-router-dom";

function Buttons({category}){
  const {
    findCategoryLaboratory
  } = useContext(CategoryLaboratoryContext)

  const deleteType = async (event) => {
    event.preventDefault();
    const confirmed = window.confirm('¿Está seguro de eliminar la categoria?')
    if (confirmed){
      try{
        const response = await axios.delete(`/api/v2/category_type_laboratory_tests/${category.id}`);
        console.log("Categoria eliminada", response.data);
        window.location.href = "/category_type_laboratory_tests";
      }catch(error){
        console.log("Error al eliminar el tipo de prueba de laboratorio")
      }
    }
  }

  const editData = async (event) => {
    event.preventDefault();
    findCategoryLaboratory(category.id);
  }

  const redirectTypeLaboratory = async (event) =>{
    event.preventDefault();
    window.location.href = `/type_laboratory_tests?id_category=${category.id}`;
  }

  return(
    <React.Fragment>
      <a
        className="btn btn-outline-primary btn-sm mr-1"
        href="#"
        onClick={redirectTypeLaboratory}
      >
        <i className="far fa-eye"/>
      </a>
      <a
        className={`btn btn-outline-success btn-sm mr-1`}
        href="#"
        onClick={editData}
      >
        <i className='far fa-edit'></i>
      </a>
      <a
        className={`btn btn-sm btn-outline-danger mr-1 hover-blank`}
        href="#"
        onClick={deleteType}
      >
        <i className='far fa-trash-alt text-danger'></i>
      </a>
    </React.Fragment>
  )
}

export { Buttons }