import React, { useState } from "react";
import { CategoryLaboratoryContext } from "../CategoryLaboratoryContext"
import { InputGroup } from "../../../InputGroup";

function Form(){
  const {
    setOpenModal,
    dataCategoryLaboratory,
    createCategoryLaboratory,
    updateCategoryLaboratory,
  } = React.useContext(CategoryLaboratoryContext);
  const [name, setName] = useState(dataCategoryLaboratory?.name || '');
  const [disabledSubmit, setDisabledSubmit] = React.useState(false)
  const onCancel = () => {
    setOpenModal(false);
  }

  const onSubmit = (e) =>{
    e.preventDefault();
    setDisabledSubmit(true);
    const newCategoryLaboratory = {
      category_type_laboratory_tests:{
        name: name
      }
    }
    setTimeout(() => {
      setDisabledSubmit(false);
    }, 4000);
    createCategoryLaboratory(newCategoryLaboratory)
  }

  const onUpdate = (e) => {
    e.preventDefault();
    setDisabledSubmit(true);
    const updateDataCategoryLaboratory = {
      category_type_laboratory_tests:{
        name: name
      }
    }
    setTimeout(() => {
      setDisabledSubmit(false);
    }, 4000);
    updateCategoryLaboratory(dataCategoryLaboratory.id, updateDataCategoryLaboratory)
  }

  return(
    <div className="modal fade show" tabIndex="-1" style={{ paddingRight: '4px', display: 'block', backgroundColor: 'rgba(3, 3, 3, 0.5)' }} role="document">
      <form onSubmit={dataCategoryLaboratory?.id ? onUpdate : onSubmit}>
        <div className="modal-dialog modal-dialog-scrollable" role="document" style={{ maxWidth: '700px' }}>
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Crear categoría</h5>
            </div>
            <div className="modal-body">
              <div className="form-group mb-3">
                <InputGroup iconClass="fas fa-file-alt" label="Nombre">
                  <input
                    type="text"
                    placeholder="Nombre de categoría"
                    className="form-control"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                </InputGroup>
              </div>
            </div>
            <div className='modal-footer'>
              <button
                type='button'
                className='btn btn-link'
                onClick={onCancel}
              >
                Cancelar
              </button>
              <button
                className='btn btn-primary mr-1'
                type="submit"
                disabled={disabledSubmit}
              >
                Crear
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  )
}

export { Form }