import React from "react";
import { TypeLaboratoriesProvider } from "./LabortoryContext";
import AppUI  from "./AppUI";

function TypeLaboratoryTests(props) {
  return(
    <TypeLaboratoriesProvider>
      <AppUI
        id_category={props.id_category_laboratory}
      />
    </TypeLaboratoriesProvider>
  )
}

export default TypeLaboratoryTests;