import React, { useContext, useEffect } from "react";
import { EmptyRecords } from "../../EmptyRecords";
import axios from "axios";
import { LoadingSpinner } from "../../LoadingSpinner";
import { LabortoryContext } from "./LabortoryContext";
import { Table } from "./Table";
import { Modal } from "./Modal"
import { EditModal } from "./EditModal";

function AppUI({id_category}) {
  const{
    loading,
    error,
    typeLaboratory,
    openModal,
    setOpenModal,
    openEditModal,
    setIdCaregory,
    findCategory,
    categoryLaboratory
  } = useContext(LabortoryContext)

  useEffect(() => {
    setIdCaregory(id_category);
    findCategory(id_category)
  }, [id_category, setIdCaregory]);

  return(
    <div className="card border-0">
      <div className="card-header d-flex justify-content-between bg-dark">
        <div className="card-title text-white mb-0 ml-xs-4">
          <a className="btn btn-link" href="/category_type_laboratory_tests">
            <i className="fas fa-arrow-left"/>
          </a>
          <h5 className="d-inline">
            Tipos de Pruebas de Laboratorio de la Categoría {categoryLaboratory}
          </h5>
        </div>
        <div className="card-options">
          <button 
            className="btn btn-primary"
            onClick={
              () => {
                setOpenModal(state => !state)
              }
            }
          >
            Nuevo
          </button>
        </div>
      </div>
      <div className="card-body p-0">
        {loading && <LoadingSpinner/>}
        {error && <p>Se generó un error :o</p>}
        {!loading && typeLaboratory.length <= 0 && <EmptyRecords/> }
        {!loading && typeLaboratory.length > 0 &&  <Table/>}
      </div>
      {openModal && (
        <Modal/>
      )
      }
      {openEditModal && (
        <EditModal/>
      )}
    </div>
  )
}

export default AppUI;