import React from "react";
import { LabortoryContext } from "../LabortoryContext";
import { FormDefinitionLaboratory } from "../FormDefinitionLaboratory";
import { DetermineLaboratoryParam } from "../DetermineLaboratoryParam";
import { InputGroup } from "../../../InputGroup";

function Modal() {
  const {
    setOpenModal,
    createServiceType,
    errorResponse,
    idCategory
  } = React.useContext(LabortoryContext);

  const [name, setName] = React.useState('');
  const [objective, setObjective] = React.useState('');
  const [qualifiedpersonnelrequired, setQualifiedpersonnelrequired] = React.useState('');
  const [standard, setStandard] = React.useState('');
  const [acceptanceCriteria, setAcceptanceCriteria] = React.useState('');

  const [definitions, setDefinitions] = React.useState([]);
  const [determinParams, setDetermineParams] = React.useState([])
  const [method, setMethod] = React.useState('')
  const [price, setPrice] = React.useState('')
  const [disabledSubmit, setDisabledSubmit] = React.useState(false)

  const onCancel = () => {
    setOpenModal(false);
  };

  const onSubmit = (e) => {
    e.preventDefault();
    setDisabledSubmit(true);
    const newLaboratoryTest = {
      type_laboratory_test: {
        name,
        objective,
        qualified_personnel_required: qualifiedpersonnelrequired,
        standard,
        acceptance_criteria: acceptanceCriteria,
        method, method,
        price: price,
        category_type_laboratory_tests_id: idCategory,
        definition_laboratories_attributes: definitions.map((definition) => ({
          id: definition.id,
          description: definition.description
        })),
        determine_laboratory_params_attributes: determinParams.map((determine) => ({
          id: determine.id,
          name: determine.name,
          description: determine.description
        })),
      }
    };
    setTimeout(() => {
      setDisabledSubmit(false);
    }, 4000);
    createServiceType(newLaboratoryTest);
  };

  const addDefinition = () => {
    const newDefinition = {
      id: Date.now(),
      description: '',
      _destroy: false,
      typeSendData: 'create'
    };
    setDefinitions([...definitions, newDefinition]);
  };

  const addDetermin = () => {
    const newDetermin ={
      id: Date.now(),
      name: '',
      description: '',
      _destroy: false,
      typeSendData: 'create'
    }
    setDetermineParams([...determinParams, newDetermin])
  }

  const updateDefinitionDescription = (id, description) => {
    setDefinitions(
      definitions.map((definition) => 
        definition.id === id ? { ...definition, description } : definition
      )
    );
  };

  const updateDeterminName = (id, name) => {
    setDetermineParams(
      determinParams.map((determin) =>
        determin.id === id ? {...determin, name} : determin
      )
    )
  }

  const updateDeterminDescription = (id, description) => {
    setDetermineParams(
      determinParams.map((determin) =>
        determin.id === id ? {...determin, description} : determin
      )
    )
  }
  const removeDefinition = (id) => {
    setDefinitions(definitions.filter((definition) => definition.id !== id));
  };

  const removeDetermin = (id) => {
    setDetermineParams(determinParams.filter((determin) => determin.id !== id))
  }

  return (
    <div className="modal fade show" tabIndex="-1" style={{ paddingRight: '4px', display: 'block', backgroundColor: 'rgba(3, 3, 3, 0.5)' }} role="document">
      <form onSubmit={onSubmit}>
        <div className="modal-dialog modal-dialog-scrollable" role="document" style={{ maxWidth: '700px' }}>
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Tipos de Pruebas de Laboratorio</h5>
            </div>
            <div className="modal-body">
              {errorResponse.length > 0 && (
                <div className="alert alert-danger" role="alert">
                  <ul>
                    {errorResponse.map((error, index) => (
                      <li key={index} style={{ color: 'black' }}>
                        {error}
                      </li>
                    ))}
                  </ul>
                </div>
              )}
              <div className="form-group mb-3">
                <InputGroup iconClass="fas fa-file-alt" label="Nombre">
                  <input
                    type="text"
                    placeholder="Nombre"
                    className="form-control"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                </InputGroup>
              </div>
              <div className="form-group mb-3">
                <InputGroup iconClass="fas fa-check" label="Objetivo">
                  <input
                    type="text"
                    placeholder="Objetivo"
                    className="form-control"
                    value={objective}
                    onChange={(e) => setObjective(e.target.value)}
                  />
                </InputGroup>
              </div>
              <h6>Definiciones</h6>
              {definitions.map((definition) => (
                <FormDefinitionLaboratory
                  key={definition.id}
                  id={definition.id}
                  description={definition.description}
                  onNameChange={updateDefinitionDescription}
                  onRemove={removeDefinition}
                />
              ))}
              <button 
                type="button" 
                className="btn btn-info btn-sm mb-3"
                onClick={addDefinition}
              >
                <i className="fas fa-plus mr-1">Agregar</i>
              </button>
              <div className="form-group mb-3">
                <InputGroup iconClass="fas fa-user-cog" label="Personal Cualificado Necesario">
                  <input
                    type="text"
                    placeholder="Personal Cualificado Necesario"
                    className="form-control"
                    value={qualifiedpersonnelrequired}
                    onChange={(e) => setQualifiedpersonnelrequired(e.target.value)}
                  />
                </InputGroup>
              </div>
              <div className="form-group mb-3">
                <InputGroup iconClass="fas fa-globe-americas" label="Norma regional, nacional o internacional en la que se basa">
                  <input
                    type="text"
                    placeholder="Norma regional, nacional o internacional en la que se basa"
                    className="form-control"
                    value={standard}
                    onChange={(e) => setStandard(e.target.value)}
                  />
                </InputGroup>
              </div>
              <h6>Parámetros por determinar</h6>
              {determinParams.map((determine) => (
                <DetermineLaboratoryParam
                  key={determine.id}
                  id={determine.id}
                  name={determine.name}
                  description={determine.description}
                  onNameChange={updateDeterminName}
                  onDescriptionChange={updateDeterminDescription}
                  onRemove={removeDetermin}
                />
              ))}
              <div className="mt-2">
                <button 
                  type="button" 
                  className="btn btn-info btn-sm mb-3"
                  onClick={addDetermin}
                >
                  <i className="fas fa-plus mr-1"> Agregar</i>
                </button>
              </div>
              
              <div className="form-group mb-3">
                <InputGroup iconClass="fas fa-file-alt" label="Criterios de aceptación">
                  <input
                    type="text"
                    placeholder="Criterios de aceptación"
                    className="form-control"
                    value={acceptanceCriteria}
                    onChange={(e) => setAcceptanceCriteria(e.target.value)}
                  />
                </InputGroup>
              </div>
              <div className="form-group mb-3">
                <InputGroup iconClass="fas fa-tools" label="Método Utilizado">
                  <input
                    type="text"
                    placeholder="Método Utilizado:"
                    className="form-control"
                    value={method}
                    onChange={(e) => setMethod(e.target.value)}
                  />
                </InputGroup>
                <InputGroup iconClass="fas fa-dollar-sign" label="Precio">
                  <input
                    type="number"
                    className="form-control"
                    name="Precio del tipo de prueba de laboratorio"
                    placeholder="Escriba el costo que tiene realizar esta prueba de laboratorio"
                    value={price}
                    onChange={(e) => {
                      const value = e.target.value;
                      if (/^\d*\.?\d{0,2}$/.test(value)) {
                        setPrice(value);
                      }
                    }}
                  />
                </InputGroup>
              </div>
            </div>
            <div className='modal-footer'>
              <button
                type='button'
                className='btn btn-link'
                onClick={onCancel}
                disabled={disabledSubmit}
              >
                Cancelar
              </button>
              <button
                className='btn btn-primary mr-1'
                type="submit"
              >
                Crear
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}

export { Modal };
