import React from 'react';

const InputGroup = ({ iconClass, label, classes, children }) => {
  return (
    <div className={`form-group ${classes || 'mb-3'} mr-2`}>
      {label && <label>{label}</label>}
      <div className="input-group">
        <span className="input-group-text">
          <i className={iconClass}></i>
        </span>
        {children}
      </div>
    </div>
  );
};

export { InputGroup }