import React, { useState } from "react";
import { LaboratoriesContext } from "../LaboratoriesContext";
import { InputGroup } from "../../InputGroup";
import { DataTest } from "../Table/DataTest";

function ApprovalOfTest(){
  const {
    setOpenModalApprovalOfTest,
    dataLaboratory,
    updateLaboratory
  } = React.useContext(LaboratoriesContext)

  const [descriptionNoApproval, setDescriptionNoApproval] = useState('')
  const [approval, setApproval] = useState(true)
  const [testExecutions, setTestExecutions] = useState(dataLaboratory?.test_executions || [])
  const [applications, setApplications] = useState(dataLaboratory?.request_tests || []);
  const [disabledSubmit, setDisabledSubmit] = useState(false)
  const [temperature, setTemperature] = useState(dataLaboratory?.temperature || [])
  const [atmosphericPressure, setAtmosphericPressure] = useState(dataLaboratory?.atmospheric_pressure || [])
  const [relativeHumidity, setRelativeHumidity] = useState(dataLaboratory?.relative_humidity || [])
  const onUpdate = (e) => {
    e.preventDefault();
    setDisabledSubmit(true);
    const dataupdateLaboratory = {
      approval_test: approval,
      description_no_approval_test: approval === true ? '' : descriptionNoApproval,
      state: approval === true ? 'awaiting_presentation_and_closure' : 'returned_aprobate_test'
    }
    setTimeout(() => {
      setDisabledSubmit(false);
    }, 4000);
    updateLaboratory(dataLaboratory.id, dataupdateLaboratory)
  }
  
  const onCancel = () => {
    setOpenModalApprovalOfTest(false);
  }
  return(
    <div className="modal fade show" tabIndex="-1" style={{ paddingRight: '4px', display: 'block', backgroundColor: 'rgba(3, 3, 3, 0.5)' }} role="document">
      <form onSubmit={onUpdate}>
        <div className="modal-dialog modal-dialog-scrollable" role="document" style={{ maxWidth: '700px' }}>
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Aprobación de Pruebas</h5>
            </div>
            <div className="modal-body">
              {applications.length > 0 ? (
                applications.map((application, index) => {
                  // Encuentra el testExecution correspondiente al application actual
                  const matchingTest = testExecutions.find(
                    test => test.type_laboratory_test_id === application.type_laboratory_test_id
                  );

                  return matchingTest ? (
                    <DataTest
                      key={application.id}
                      dataLaboratory={application}
                      testExecution={matchingTest}
                    />
                  ) : null;
                })
              ) : (
                <p>No hay pruebas seleccionadas.</p>
              )}
              <table className="table table-bordered mt-4">
                <thead className="bg-danger text-white">
                  <tr>
                    <th colSpan={3}>
                      Condiciones Ambientales
                    </th>                    
                  </tr>
                  <tr>
                    <th>Temperatura °C</th>
                    <th>Presión Atmosférica</th>
                    <th>Humedad Relativa %</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <textarea
                        className="form-control"
                        value={temperature}
                        disabled={true}
                      />
                    </td>
                    <td>
                      <textarea
                        className="form-control"
                        value={atmosphericPressure}
                        disabled={true}
                      />
                    </td>
                    <td>
                      <textarea
                        className="form-control"
                        value={relativeHumidity}
                        disabled={true}
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
              <div className="card">
                <div className="form-check form-check-inline ml-1 mt-4">
                  ¿Aprueba?
                  <div className="ml-3 mt-2">
                    <input
                      type="radio"
                      name="approval" // Mismo name para ambos botones
                      value={true}
                      checked={approval === true}
                      onChange={() => setApproval(true)} // Cambiar a true
                    />
                    <label>Si</label>
                  </div>
                  <div className="ml-3 mt-2">
                    <input
                      type="radio"
                      name="approval" // Mismo name para ambos botones
                      value={false}
                      checked={approval === false}
                      onChange={() => setApproval(false)} // Cambiar a false
                    />
                    <label>No</label>
                  </div>
                </div>
                { approval === false &&
                  <InputGroup iconClass="far fa-clipboard" label="Motivo">
                    <textarea
                      value={descriptionNoApproval}
                      placeholder="Escribe el motivo de la no aprobación"
                      onChange={(e) => setDescriptionNoApproval(e.target.value)}
                      className="form-control"
                    />
                  </InputGroup>
                }
              </div>
            </div>
            <div className='modal-footer'>
              <button
                type='button'
                className='btn btn-link'
                onClick={onCancel}
              >
                Cancelar
              </button>
              <button
                className='btn btn-primary mr-1'
                type="submit"
                disabled={disabledSubmit}
              >
                Envíar
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  )
}

export { ApprovalOfTest }