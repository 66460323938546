import React from "react";
import { CategoryLaboratoryProvider } from "./CategoryLaboratoryContext";
import AppUI from "./AppUI";

function CategoryLaboratoryTests(){
  return(
    <CategoryLaboratoryProvider>
      <AppUI/>
    </CategoryLaboratoryProvider>
  )
}

export default CategoryLaboratoryTests;