import React from "react";
import { LaboratoriesContext } from "../LaboratoriesContext";
import { Buttons } from "../Buttons"
import Assignments from "../../Assignments";
import { CollapseTableUser } from "../CollapseTableUser";
import { CollapseTableType } from "../CollapseTableType";
import Tooltip from "../../Tooltip";

function Table(){
  const {
    laboratory
  } = React.useContext(LaboratoriesContext)
  const [openCollapses, setOpenCollapses] = React.useState({})
  const [openCollapseType, setOpenCollapseType] = React.useState({})

  const type_state = (data) => {
    let name = '';
    switch(data) {
        case 'rejected':
            name = 'No aprobada';
            break;
        case 'awaiting_presentation_and_closure':
            name = 'Esperando envío de informe y cierre';
            break;
        case 'awaiting_approved':
            name = 'Esperando aprobación';
            break;
        case 'awaiting_test_approval':
            name = 'Esperando la ejecución de pruebas'
            break;
        case 'returned_aprobate_test':
            name = 'No aprobada'
            break;
        case 'closed':
            name = 'Cerrada'
            break;
        default:
            name = 'Estado desconocido';
    }
    return name;
  }

  const toggleCollapse = (index) => {
    setOpenCollapses((prevState) => ({
      ...prevState,
      [index]: !prevState[index], // Alterna el estado del colapso según el índice
    }));
  }

  const toggleCollapseType = (index) => {
    setOpenCollapseType((prevState) => ({
      ...prevState,
      [index]: !prevState[index], // Alterna el estado del colapso según el índice
    }));
  }
  return(
    <React.Fragment>
      <table className="table table-bordered table-hover table-sm table-striped">
        <thead>
          <tr>
            <th className="align-middle text-center" style={{width: "5%"}}>Código</th>
            <th className="align-middle text-center" style={{width: "7%"}}>Acciones</th>
            <th className="align-middle text-center" style={{width: "9%"}}>Documentos</th>
            <th className="align-middle text-center" style={{width: "9%"}}>Estado</th>
            <th className="align-middle text-center" style={{width: "9%"}}>Responsable</th>
            <th className="align-middle text-center">Fecha</th>
            <th className="align-middle text-center">Nombre de Persona que realizó la solicitud</th>
            <th className="align-middle text-center">Descripción de Solicitud</th>
            <th className="align-middle text-center" style={{width: "7%"}}>Cantidad por Lotes</th>
            <th className="align-middle text-center" style={{width: "7%"}}>Unidades por Lote</th>
          </tr>
        </thead>
        <tbody>
          {laboratory.length > 0 && 
            laboratory.map((laboratory, index) =>(
              <React.Fragment key={index}>
                <tr>
                  <td className="align-middle text-center">
                    {laboratory.code}
                  </td>
                  <td className="align-middle text-center">
                    <div className="d-flex justify-content-between ml-2">
                      <Buttons
                        state={laboratory.state}
                        laboratory_id={laboratory.id}
                        laboratory={laboratory}
                      />
                    </div>
                  </td>
                  <td className="align-middle text-center">
                    { laboratory?.file_payment ? (
                      <Tooltip text={"Evidencia de pago de pruebas de laboratorio"}>
                        <a
                          href={laboratory.file_payment}
                          className={`btn btn-outline-success btn-sm mr-2`}
                          target="_blank"                 
                        >
                          <i className="far fa-file" />
                        </a>
                      </Tooltip>
                      ) : (
                        <a
                          className={`btn btn-outline-success btn-sm mr-2 disabled`}
                        >
                          <i className="far fa-file" />
                        </a>
                      ) 
                    }
                    { laboratory?.file_report ? (
                      <Tooltip text={"Informe Final"}>
                        <a
                          href={laboratory.file_report}
                          className={`btn btn-outline-info btn-sm mr-2`}
                          target="_blank"                 
                        >
                          <i className="far fa-file" />
                        </a>
                      </Tooltip>
                      ) : (
                        <a
                          className={`btn btn-outline-info btn-sm mr-2 disabled`}
                        >
                          <i className="far fa-file" />
                        </a>
                      ) 
                    }
                    { laboratory?.file_report_edit && (
                      <Tooltip text={"Informe Editado"}>
                        <a
                          href={laboratory.file_report_edit}
                          className={`btn btn-outline-info btn-sm mr-2`}
                          target="_blank"                 
                        >
                          <i className="far fa-file" />
                        </a>
                      </Tooltip>
                    )}
                    { laboratory?.file_report_edit_two && (
                      <Tooltip text={"Segundo Informe Editado"}>
                        <a
                          href={laboratory.file_report_edit_two}
                          className={`btn btn-outline-info btn-sm mr-2`}
                          target="_blank"                 
                        >
                          <i className="far fa-file" />
                        </a>
                      </Tooltip>
                    )}
                  </td>
                  <td className="align-middle text-center">
                    { type_state(laboratory.state) }
                  </td>
                  <td className="align-middle text-center">
                    {laboratory?.assignments?.length > 0 ? (
                      <Assignments
                        assignable={laboratory.id}
                        action={'responsible'}
                      />) 
                      : 
                      (
                        <p>No asignado</p>
                      )
                    }
                  </td>
                  <td className="align-middle text-center">
                    {laboratory.date}
                  </td>
                  <td className="align-middle text-center">
                    <a onClick={() => toggleCollapse(index)} style={{ cursor: 'pointer' }}>
                      {laboratory.name_company_name}
                    </a>
                  </td>
                  <td className="align-middle text-center">
                    <a onClick={() => toggleCollapseType(index)} style={{ cursor: 'pointer' }}>
                      {laboratory.description_application}
                    </a>
                  </td>
                  <td className="align-middle text-center">
                    {laboratory.number_lots}
                  </td>
                  <td className="align-middle text-center">
                    {laboratory.units_lot}
                  </td>
                </tr>
                <tr>
                  <th colSpan="11">
                    <div className={`collapse ${openCollapses[index] ? 'show' : ''}`}>
                      <CollapseTableUser laboratory={laboratory} />
                    </div>
                    <div className={`collapse ${openCollapseType[index] ? 'show' : ''}`}>
                      <CollapseTableType laboratory={laboratory} />
                    </div>
                  </th>
                </tr>
              </React.Fragment>
            )            
          )}
        </tbody>
      </table>
    </React.Fragment>
  )
}

export { Table }