import React from 'react'

const Header = ({setModalSearch, buttonFlag, permissions, user, portal}) => {

  // Log variables to console
  const canCreateIndicators = portal.clinizad 
    ? (permissions.admin_sg || user.admin || permissions.user_manager) 
    : true;

  const handleDownload = async (event) => {
    const csrfToken = document.querySelector('meta[name="csrf-token"]').content;
    event.preventDefault();

    try {
      const response = await fetch(`/indicators/download_indicators`, {
        method: 'POST',
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          'X-CSRF-Token': csrfToken
        },
      });

      if (response.ok) {
        window.location.href = '/indicators/list';
      } else {
        console.error('Error al descargar');
      }
    } catch (error) {
      console.error('Error al descargar', error);
    }
  }

  return (
    <>
      <div className="card border-0" data-sidebar-section="js-assessment-section" data-sidebar-menu="js-indicators-menu">
        <div className="card-header d-flex justify-content-between position-sticky sticky-top bg-dark">
          <div className="card-title mb-0 d-flex align-items-center">
            <h5 className="mb-0 text-white ml-xs-4">Indicadores</h5>
          </div>
          <div className='d-flex justify-content-end py-md-3 py-lg-1 py-4'>
          {buttonFlag && <>
              <a className='btn btn-danger ml-2' href='/indicators/list'>
                <i className='fas fa-times'></i>
              </a>
            </>}
            <button className='btn mx-1' onClick={()=> setModalSearch(true)}>
              <span className='mr-2 font-weight-light text-white'>Búsqueda Avanzada</span>
              <i className='text-white fas fa-search'></i>
            </button>
            {canCreateIndicators && <>
                <a className='btn btn-primary mr-1' href='/indicators/new'>Nuevo</a>
              </>}
            <a className='btn btn-success hover-blank' href='#' onClick={handleDownload}>
              <i className='fas fa-file-download'></i>
            </a>
          </div>
        </div>
      </div>
    </>
  )
}
export default Header
