import React from "react";
import { serviceTypeLaboratory } from "./serviceTypeLaboratory";
import axios from "axios";

const LabortoryContext = React.createContext()

function TypeLaboratoriesProvider({children}){
  const {
    loading,
    error,
    typeLaboratory,
    setIdCaregory,
    idCategory,
  } = serviceTypeLaboratory()
  const [openModal, setOpenModal] = React.useState(false)
  const [errorResponse, setErrorResponse] = React.useState([])
  const [datatypeLaboratory, setDataTypeLaboratory] = React.useState([])
  const [openEditModal, setOpenEditModal] = React.useState(false)
  const [categoryLaboratory, setCategoryLaboratory] = React.useState("")

  const createServiceType = async (newLaboratoryTest) => { 
    try {
      const response = await axios.post("/api/v2/type_laboratory_tests", newLaboratoryTest);
      console.log("Prueba de laboratorio creada:", response.data);
      window.location.href = `/type_laboratory_tests?id_category=${idCategory}`;
      setOpenModal(false)
      setErrorResponse([]);
    } catch (error) {
      console.error("Error al crear la prueba de laboratorio:");      
      setErrorResponse(error.response.data.errors);
    }
  };
  
  const findTypeLaboratory = async (idLaboratoryTest) => {
    try{
      const response = await  axios.get(`/api/v2/type_laboratory_tests/${idLaboratoryTest}/edit`)
      setOpenEditModal(true)
      setDataTypeLaboratory(response.data)
    }catch (error){
      setOpenEditModal(false)
    }
  }
  const updateTypeLaboratory = async (id, newLaboratoryTest) => {
    try{
      const response = await axios.patch(`/api/v2/type_laboratory_tests/${id}`, newLaboratoryTest)
      console.log("Prueba de laboratorio editada con éxito");
      window.location.href = `/type_laboratory_tests?id_category=${idCategory}`;
      setOpenEditModal(false)
      setErrorResponse([])
    } catch (error){
      setErrorResponse(error.response.data.errors)
    }
  }

  const findCategory = async (id) => {
    if (idCategory === "") return
    try{
      const response = await axios.get(`/api/v2/category_type_laboratory_tests/name_category?id_category=${id}`)
      setCategoryLaboratory(response.data)
    }
    catch(error){
      console.log('Se generó un error al buscar la categoría')
    }
  }

  return(
    <LabortoryContext.Provider
      value={{
        loading,
        error,
        typeLaboratory,
        openModal,
        setOpenModal,
        createServiceType,
        errorResponse,
        findTypeLaboratory,
        openEditModal,
        setOpenEditModal,
        datatypeLaboratory,
        updateTypeLaboratory,
        setIdCaregory,
        findCategory,
        categoryLaboratory,
        setCategoryLaboratory,
        idCategory

      }}
    >
      {children}
    </LabortoryContext.Provider>
  )
}

export {LabortoryContext, TypeLaboratoriesProvider }