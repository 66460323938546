import React, { useContext } from "react";
import { format } from 'date-fns';
import { formatDistanceToNow } from 'date-fns';
import { es, enUS } from 'date-fns/locale';
import { CategoryLaboratoryContext } from "../CategoryLaboratoryContext";
import { Buttons } from "../Buttons";

function Table(){
  const {
    categoryLaboratory,
  } = useContext(CategoryLaboratoryContext)
  return(
    <table className="table table-bordered table-hover table-sm table-striped">
      <thead>
        <tr>
          <th className="text-center" scope="col" style={{width: '30px'}}> # </th>
          <th className="text-center" scope="col" style={{width: '130px'}}/>
          <th>Nombre</th>
          <th>Fecha Creación</th>
          <th>Última Actualización</th>
        </tr>
      </thead>
      <tbody>
        {
          categoryLaboratory.map((category, index) => {
            return(
              <React.Fragment key={index + 1}>
                <tr>
                  <td className="align-middle text-center"> {index + 1} </td>
                  <td className="align-middle">
                    <Buttons
                      category={category}
                    />
                  </td>
                  <td className="align-middle">{category.name}</td>
                  <td className="align-middle text-center">
                    <small>{format(new Date(category.created_at), 'dd-MM-yy hh:mm a')} </small>
                    <small className="d-block">{formatDistanceToNow(new Date(category.created_at), { locale: es, addSuffix: true })}</small>
                  </td>
                  <td className="align-middle text-center">
                    <small>{format(new Date(category.updated_at), 'dd-MM-yy hh:mm a')} </small>
                    <small className="d-block">{formatDistanceToNow(new Date(category.updated_at), { locale: es, addSuffix: true })}</small>
                  </td>
                </tr>
              </React.Fragment>
            )
          })
        }
      </tbody>
    </table>
  )
}

export { Table }