import React, { useContext } from "react";
import { InputGroup } from "../../InputGroup";
import { useEffect, useState } from "react";
import axios from "axios";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import ReCAPTCHA from 'react-google-recaptcha';
import "../../../../javascript/styles/form_laboratory.css"
import Uploader from "../../BusinessModules/components/Uploader";

function New({portal, color}){
  const [errorResponse, setErrorResponse] = useState([])
  const [typeLaboratories, setTypeLaboratories] = useState([])
  const [categoryTypeLaboratories, setCategoryTypeLaboratories] = useState([])
  const [date, setDate] = useState(new Date())
  const [nameCompanyName, setNameCompanyName] = useState('')
  const [indentification, setIndentification] = useState('')
  const [address, setAdress] = useState('')
  const [phone, setPhone] = useState('')
  const [email, setEmail] = useState('')
  const [applications, setApplications] = useState([])
  const [checkCategory, setCheckCategory] = useState([])
  const [numberlots, setNumberLots] = useState(1)
  const [unitslot, setUnitsLot] = useState(1)
  const [descriptionApplication, setDescriptionApplication] = useState('')
  const [descriptionSampleCollection, setDescriptionSampleCollection] = useState('')
  const [contactPerson, setContactPerson] = useState('')
  const [disabledSubmit, setDisabledSubmit] = useState(false)
  const [recaptchaValue, setRecaptchaValue] = useState(null)
  const [error, setError] = useState(false);
  const [collectLaboratory, setCollectLaboratory] = useState([])
  const [price, setPrice] = useState(0)
  const [totalPrice, setTotalPrice] = useState(0)
  const [createdUser, setCreatedUser] = useState("")
  const [filePayment, setFilePayment] = useState("")
  const [disabledDataUSer, setDisabledDataUser] = useState(false)
  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const url = `/api/v2/laboratories/category_type_laboratories.json?portal_id=${portal.id}`;
        const response = await axios.get(url, {
          headers: { Accept: "application/json" },
        });
        if (response.status === 200) {
          setCategoryTypeLaboratories(response.data);
        }
      } catch {
        setError(true);
      }
    };

    fetchCategories();
  }, [portal.id]);

  useEffect(() => {
    const fetchTests = async () => {
      try {
        const url = `/api/v2/laboratories/gettype_laboratory_test.json?portal_id=${portal.id}`;
        const response = await axios.get(url, {
          headers: { Accept: "application/json" },
        });
        if (response.status === 200) {
          setTypeLaboratories(response.data);
        }
      } catch {
        setError(true);
      }
    };
  
    fetchTests();
  }, [portal.id]);

  const checkboxHandlerCategory = (e) => {
    const { checked, value } = e.target;
    setCheckCategory((prev) =>
      checked ? [...prev, value] : prev.filter((id) => id !== value)
    );
  };

  useEffect(() => {
    if (checkCategory.length > 0) {
      setCollectLaboratory((prevLaboratories) =>
        prevLaboratories.filter((item) =>
          checkCategory.includes(item.category_type_laboratory_tests_id)
        )
      );
  
      checkCategory.forEach((category) => {
        typeLaboratories.forEach((type) => {
          if (type.category_type_laboratory_tests_id == category) {
            const exists = collectLaboratory.some(
              (item) => item.id === type.id
            );
            if (!exists) {
              setCollectLaboratory((prevLaboratories) => [
                ...prevLaboratories,
                type,
              ]);
            }
          }
        });
      });
    } else {
      setCollectLaboratory([]);
    }
  }, [checkCategory]);

  useEffect(() => {
    if(indentification != ""){
      const fetchLogged = async() => {
        try{
          const url = `api/v2/laboratories/created_user.json?identification=${indentification}`;
          const response = await axios.get(url, {
            headers: { Accept: "application/json"}
          });
          if (response.status === 200){
            setCreatedUser(response.data)
            if(response.data.created === true){
              const { address, contact_person, email, identification, name_company_name, phone } = response.data.data;
              setAdress(address || "");
              setContactPerson(contact_person || "");
              setEmail(email || "");
              setNameCompanyName(name_company_name || "");
              setPhone(phone || "");
              setDisabledDataUser(true)
            } else{
              setDisabledDataUser(false)
              setAdress("");
              setContactPerson("");
              setEmail("");
              setNameCompanyName("");
              setPhone("");
            }
          }
        } catch(error){
          console.log(`se presentó error al tratar de encontrar la identificación`);
          setDisabledDataUser(false)
        }
      };
      fetchLogged();
    };

  }, [indentification])

  const checkboxHandler = (e) => {
    const { checked, value } = e.target;
    setApplications((prev) =>
      checked ? [...prev, value] : prev.filter((id) => id !== value)
    );
    setPrice((prevPrice) => {
      const selectedType = typeLaboratories.find((type) => type.id === value);
      if (selectedType) {
        if (checked) {
          const selectedPrice = selectedType.price;
          const total = prevPrice + selectedPrice
          const calculatedTotal = total * unitslot * numberlots;
          setTotalPrice(calculatedTotal)
          return total;
        } else {
          const selectedPrice = prevPrice - selectedType.price
          const calculatedTotal = selectedPrice * unitslot * numberlots;
          setTotalPrice(calculatedTotal)
          return selectedPrice;
        }
      }
      return prevPrice;
    });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    setDisabledSubmit(true);
    const laboratory = {
      recaptchaValue: recaptchaValue || undefined,
      createdUser: createdUser,
      laboratory: {
        date: date,
        name_company_name: nameCompanyName,
        indentification: indentification,
        address: address,
        phone: phone,
        email: email,
        number_lots: numberlots,
        units_lot: unitslot,
        portal_id: portal.id,
        description_application: descriptionApplication,
        description_sample_collection: descriptionSampleCollection,
        contact_person: contactPerson,
        total_price: totalPrice,
        file_payment: filePayment || undefined,
        request_tests_attributes: applications.map((test) => {
          return{
            type_laboratory_test_id: test,
            portal_id: portal.id,
            _destroy: false
          }
        }),
      }
    }
    console.log(laboratory)
    setTimeout(() => {
      setDisabledSubmit(false);
    }, 4000);
    createLaboratory(laboratory)
  }

  const createLaboratory = async (laboratory) => {
    try {
      const token = document
        .querySelector('meta[name="csrf-token"]')
        .getAttribute("content");
      const response = await axios.post("/api/v2/laboratories", laboratory , 
        {
          headers: {
            "X-CSRF-Token": token,
            "Content-Type": "application/json",
          },
        }
      )
      window.location.href = `/main_laboratories_success?laboratory_id=${response.data.id}&portal=true&layout=pqrsf`;
      setErrorResponse([])
    } catch (error){
      console.error("Error al crear la prueba de laboratorio:", error.response.data.errors);
      setErrorResponse(error.response.data.errors)
    }
  }

  const handleRecaptchaChange = (value) => {
    setRecaptchaValue(value); // Guarda el valor del token generado por reCAPTCHA
  };

  const calculateUnitsLote = (e) => {
    const number = e.target.value;
    const calculatedTotal = (price * number) * numberlots;
    setUnitsLot(number);
    setTotalPrice(calculatedTotal);
  }

  const calculateNumberLots = (e) => {
    const number = e.target.value;
    const calculatedTotal = (price * unitslot) * number;
    setNumberLots(number);
    setTotalPrice(calculatedTotal);

  }

  const handleFileUpload = (file) => {
    setFilePayment(file)
  };

  return(
    <div className="card card border-info px-2 py-2">
      <form onSubmit={onSubmit}>
        <div className="card-header bg-white">
          <a
            className="btn btn-secondary.btn-sm floating-top-left rounded-circle"
            href="/main_laboratories?portal_id=6011faf0-0604-40c6-a347-c2aead8e50ce&layout=pqrsf&color=true"
          >
            <i className="fas fa-arrow-left"/>
          </a>
          <div className="d-flex justify-content-center">
            <div className="max-450px">
              <h3 className="text-wrap font-weight-bold text-dark">
                {portal.short_name}
              </h3>
            </div>
          </div>
          <div className="h4 text-center font-weight-bold text-dark ml-3">
            Laboratorio
          </div>
        </div>
        {errorResponse.length > 0 && (
          <div className="alert alert-danger" role="alert">
            <ul>
              {errorResponse.map((error, index) => (
                <li key={index} style={{ color: 'black' }}>
                  {error}
                </li>
              ))}
            </ul>
          </div>
        )}
        <div className="card border-0 shadow-sm rounded-lg mb-4">
          <div
            className={`card-header text-white ${
              color ? "" : "bg-dark"
            } d-flex align-items-center`}
            style={{ backgroundColor: color || "#343a40" }}
          >
            <h5 className="font-weight-bold mb-0">Información del Solicitante</h5>
          </div>
          <div className="card-body">
            <form>
              <div className="row mb-4">
                <div className="col-md-4 mb-3">
                  <p className="text-muted">
                    Complete el siguiente formulario para registrarse en el laboratorio KKRAAEE TECH S.A.S. Los datos ingresados se utilizarán para procesar su solicitud y futuras pruebas de laboratorio. 
                    La cédula o RUC se usará para verificar solicitudes previas.
                    <strong> Por favor, revise cuidadosamente la información ingresada para garantizar su precisión.</strong>
                  </p>
                  <InputGroup iconClass="fas fa-id-card" label="Cédula o RUC (*)">
                    <input
                      type="text"
                      value={indentification}
                      className="form-control"
                      name="Cédula o RUC"
                      placeholder="Ingrese su cédula o RUC"
                      onChange={(e) => setIndentification(e.target.value)}
                    />
                  </InputGroup>
                </div>

                <div className="col-md-8 d-flex align-items-center">
                  {createdUser !== "" && (
                    <div 
                      className={`alert ${createdUser.created ? "alert-success" : "alert-danger"} w-100`}
                    >
                      <p className="mb-0">
                        {createdUser.created ? (
                          <>Ya existe una solicitud de laboratorio asociada con esta Cédula o RUC.<br />
                          <strong>Nota:</strong> Si necesita realizar cambios en los datos proporcionados, comuníquese al número: .</>
                        ) : (
                          <>No se encontró una solicitud previa asociada a esta Cédula o RUC. Esta es su primera solicitud.</>
                        )}
                      </p>
                    </div>
                  )}
                </div>
              </div>
              <div className="row mb-3">
                <div className="col-md-4">
                  <InputGroup iconClass="fas fa-map-pin" label="Dirección (*)">
                    <input
                      value={address}
                      type="text"
                      className="form-control"
                      name="Dirección"
                      placeholder="Ingrese su dirección"
                      onChange={(e) => setAdress(e.target.value)}
                      disabled={disabledDataUSer}
                    />
                  </InputGroup>
                </div>
                <div className="col-md-4">
                  <InputGroup iconClass="fas fa-user" label="Nombre o Razón Social (*)">
                    <input
                      value={nameCompanyName}
                      type="text"
                      className="form-control"
                      name="Nombre o Razón Social"
                      placeholder="Ingrese nombre o razón social"
                      onChange={(e) => setNameCompanyName(e.target.value)}
                      disabled={disabledDataUSer}
                    />
                  </InputGroup>
                </div>
                <div className="col-md-4">
                  <InputGroup iconClass="fas fa-id-card" label="Persona de Contacto (*)">
                    <input
                      value={contactPerson}
                      type="text"
                      className="form-control"
                      name="Persona de contacto"
                      placeholder="Ingrese persona de contacto"
                      onChange={(e) => setContactPerson(e.target.value)}
                      disabled={disabledDataUSer}
                    />
                  </InputGroup>
                </div>
              </div>
              <div className="row mb-3">
                <div className="col-md-4">
                  <InputGroup iconClass="fas fa-mobile-alt" label="Teléfono de Contacto (*)">
                    <PhoneInput
                      value={phone}
                      defaultCountry="CO"
                      onChange={(phone) => setPhone(phone)}
                      className="form-control"
                      disabled={disabledDataUSer}
                    />
                  </InputGroup>
                </div>
                <div className="col-md-4">
                  <InputGroup iconClass="fas fa-envelope" label="Correo Electrónico (*)">
                    <input
                      value={email}
                      type="email"
                      className="form-control"
                      name="Correo Electrónico"
                      placeholder="Ingrese su correo electrónico"
                      onChange={(e) => setEmail(e.target.value)}
                      disabled={disabledDataUSer}
                    />
                  </InputGroup>
                </div>
                <div className="col-md-4">
                  <InputGroup iconClass="fas fa-calendar" label="Fecha (*)">
                    <DatePicker
                      selected={date}
                      onChange={(date) => setDate(date)}
                      showMonthDropdown
                      className="form-control"
                    />
                  </InputGroup>
                </div>
              </div>
              <div className="row">
              </div>
            </form>
          </div>
        </div>
        <div className="card border-info shadow-sm mb-4 rounded">
          <div className={`card-header ${color? '' : 'bg-dark'}`} style={{backgroundColor: color}}>
            <h5 className="font-weight-bold text-white">
              SOLICITUD
            </h5>
          </div>
          <div className="card-body">
            <div className="row mb-4 p-4 border rounded bg-white shadow-sm">
              <div className="col-12 mb-3">
                <h5 className="font-weight-bold text-primary mb-3">
                  Selecciona las pruebas
                </h5>
                <p className="text-muted">Elige una o más categorías para mostrar las pruebas relacionadas.</p>
              </div>

              {/* Categorías */}
              <div className="col-12 mb-4">
                <div className="d-flex flex-wrap gap-3">
                  {categoryTypeLaboratories.map((category, index) => (
                    <div className="form-check form-switch mr-3" key={category.id}>
                      <input
                        type="checkbox"
                        className="form-check-input"
                        id={`category-${index}`}
                        value={category.id}
                        checked={checkCategory.includes(category.id)}
                        onChange={checkboxHandlerCategory}
                        style={{ cursor: "pointer" }}
                      />
                      <label
                        className="form-check-label ms-2"
                        htmlFor={`category-${index}`}
                        style={{ cursor: "pointer" }}
                      >
                        {category.name}
                      </label>
                    </div>
                  ))}
                </div>
              </div>

              {/* Pruebas */}
              <div className="col-12">
                <h6 className="font-weight-bold text-secondary mb-3">
                  Pruebas disponibles
                </h6>
                <div className="d-flex flex-wrap gap-3">
                  {collectLaboratory.length > 0 ? (
                    collectLaboratory.map((type, index) => (
                      <div className="form-check-container mr-3" key={index}>
                        <div className="form-check form-switch">
                          <input
                            type="checkbox"
                            className="form-check-input"
                            id={`type-${index}`}
                            name={type.name}
                            value={type.id}
                            checked={applications.includes(type.id)}
                            onChange={checkboxHandler}
                          />
                          <label className="form-check-label" htmlFor={`type-${index}`}>
                            {type.name}
                          </label>
                        </div>
                        <div className="price-info">
                          Precio: <span className="price">${type.price}</span>
                        </div>
                      </div>
                    ))
                  ) : (
                    <p className="text-muted">Selecciona una categoría para ver las pruebas disponibles.</p>
                  )}
                </div>
              </div>

              {/* Error */}
              {error && (
                <div className="col-12 mt-4">
                  <div className="alert alert-danger" role="alert">
                    Ocurrió un error al cargar los datos.
                  </div>
                </div>
              )}
            </div>
            <div className="form-row mt-2">
              <div className="col-md-4">
                <InputGroup iconClass="fas fa-building" label="Unidades por Lote (*)">
                  <input
                    type="number"
                    className="form-control"
                    name="Unidades por Lote"
                    placeholder="Unidades por Lote"
                    value={(unitslot)}
                    onChange={calculateUnitsLote}
                  />
                </InputGroup>
              </div>
              <div className="col-md-4">
                <InputGroup iconClass="fas fa-building" label="Cantidad por Lotes (*)">
                  <input
                    type="number"
                    className="form-control"
                    name="Cantidad por Lotes"
                    placeholder="Cantidad por Lotes"
                    value={(numberlots)}
                    onChange={calculateNumberLots}
                  />
                </InputGroup>
              </div>
              <div className="col-md-4">
                <InputGroup iconClass="fas fa-building" label="Descripción Solicitud (*)">
                  <input
                    type="text"
                    className="form-control"
                    name="Descripción Solicitud"
                    placeholder="Descripción Solicitud"
                    onChange={(e) => setDescriptionApplication(e.target.value)}
                  />
                </InputGroup>
              </div>
            </div>
            <div className="form-row mt-2">
              <div className="col-md-4">
                <InputGroup iconClass="fas fa-building" label="Descripción Punto Toma de Muestra (*)">
                  <input
                    type="text"
                    className="form-control"
                    name="Descripción Solicitud"
                    placeholder="Descripción Punto Toma de Muestra"
                    onChange={(e) => setDescriptionSampleCollection(e.target.value)}
                  />
                </InputGroup>
              </div>
            </div>
            <div className="card p-4 shadow-sm border-0 mb-4">
              <h4 className="text-primary mb-3">Precio Total</h4>
              <div className="d-flex justify-content-between align-items-center">
                <p className="mb-0 text-secondary">Total por la(s) prueba(s) de laboratorio selecionada(s):</p>
                <h3 className="text-success mb-0">
                  ${totalPrice.toFixed(2)}
                </h3>
              </div>
            </div>
          </div>
          <Uploader onChange={handleFileUpload} />
        </div>
        <div className="col-md-12 text-center">
          <div className="d-flex justify-content-center mb-2">
            <ReCAPTCHA
              sitekey="6Lcf7W4qAAAAAAbdjue-T58Br5sBSRCf-AbqnAqa"
              onChange={handleRecaptchaChange}
            />
          </div>
          <button
            className="btn btn-primary"
            disabled={disabledSubmit}
            style={{backgroundColor: color}}
          >
            Enviar Solicitud
          </button>
        </div>
      </form>
    </div>
  )
}

export {New}