document.addEventListener("DOMContentLoaded", function() {

  var modalPresente = document.querySelector('.MyUniqueModal') !== null;

  var url = window.location.pathname
  if ((url === '/' || url.includes("/user.") || url === '/procedures/map' || url=== '/user' || url.includes("/user/edit")) && modalPresente)
  {
    // Este código se ejecutará cuando se cargue la vista "example_view"
    // Agrega aquí tu lógica personalizada.

    var dateupdate = new Date($('#date_update_password').val());

    var portal = upl_portal($('#date_update_password').attr("name"))
    // var montagas = portal_motagas($('#date_update_password').attr("name"))
    var currentDate = new Date();
    var verDate = dateupdate instanceof Date && !isNaN(dateupdate);
    dateupdate.setFullYear(dateupdate.getFullYear() + 1);
    var modal = $("#ModalPassword");
    if (dateupdate < currentDate && portal || !verDate && portal) {
      modal.show();
    }

    $('#password_confirmation').on('keyup', function() {
      var password = $('#password').val();
      var confirmPassword = $(this).val();
      var strength = checkPasswordStrength(password);
      var message = funConfirmPassword(password, confirmPassword, strength)

      $('#password-confirmation-error').text(message);
    });

    $('#password').on('keyup', function() {
      var password = $(this).val();
      var confirmPassword = $('#password_confirmation').val();
      var strength = checkPasswordStrength(password);
      funConfirmPassword(password, confirmPassword, strength)

    });

    function upl_portal(portal_id) {
      var upl = ['248042eb-cfa2-419b-8b48-a665b576623a', '963a1bba-dc52-4d5f-808e-40e5a686de22', '21aed10a-dd78-4b86-9780-0e12b7fdc20c', '8218d114-67f0-4699-9f52-176d6425b46b'];

      // Verificar si el elemento está en el arreglo
      if ($.inArray(portal_id, upl) !== -1) {
        return false;
      } else {
        return true;
      }
    }

    // function portal_motagas(portal_id) {
    //   if (portal_id == "096ea912-70d8-4d11-8f72-c99fc48a2c0d") {
    //     return true;
    //   } else {
    //     return false;
    //   }
    // }

    function funConfirmPassword(password, confirmPassword, strength) {
      var message = ''
      $('#password-strength').text(strength);
      if (password === confirmPassword){
        if (strength === 'Fuerte'){
          $('#submit_button').prop('disabled', false);
        }
        else{
          $('#submit_button').prop('disabled', true);
        }
      }
      else{
        message = 'Las contraseñas no coinciden';
        $('#submit_button').prop('disabled', true);
      }
      return message
    }

    function checkPasswordStrength(password) {
      // Check length
      if (password.length < 8) {
        return 'Débil (La contraseña debe tener al menos 8 caracteres)';
      }

      // Check character types
      var hasUpperCase = /[A-Z]/.test(password);
      var hasLowerCase = /[a-z]/.test(password);
      var hasNumbers = /\d/.test(password);
      var hasSpecialChars = /[!@#$%^&*()_+[\]{};':"\\|,.<>/?]/.test(password);

      if (hasUpperCase && hasLowerCase && hasNumbers && hasSpecialChars) {
        return 'Fuerte';
      } else {
        var missingTypes = [];
        if (!hasUpperCase) missingTypes.push('mayúsculas');
        if (!hasLowerCase) missingTypes.push('minúsculas');
        if (!hasNumbers) missingTypes.push('números');
        if (!hasSpecialChars) missingTypes.push('caracteres especiales');
        return 'Moderada (La contraseña debe contener ' + missingTypes.join(', ') + ')';
      }
    }
  }
});
