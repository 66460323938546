import React, { useEffect, useState } from "react";
import { AdminLaboratoriesContext } from "../AdminLaboratoriesContext";
import Assignments from "../../../Assignments";

function Table() {
  const { adminLaboratory } = React.useContext(AdminLaboratoriesContext);
  const [qrCode, setQrCode] = useState(null);
  const [image, setImage] = useState([]);

  console.log(image)
  useEffect(() => {
    fetch('/api/v2/admin_laboratories/generate_qr')
      .then((response) => response.json())
      .then((data) => {
        setQrCode(`data:image/png;base64,${data.qr_code}`);
      })
      .catch((error) => {
        console.error('Error fetching QR Code:', error);
      });
  }, []);


  useEffect(() => {
    fetch('/api/v2/admin_laboratories/image_portal')
      .then((response) => response.json())
      .then((data) => {
        setImage(data);
      })
      .catch((error) => {
        console.error('Error fetching image:', error);
      });
  }, []);

  return (
    <div>
      {adminLaboratory && adminLaboratory.length > 0 ? (
        adminLaboratory.map((admin, index) => (
          <React.Fragment key={index}>
            <table className="table table-bordered table-hover table-sm table-striped">
              <thead>
                <tr>
                  <th
                    className="text-center align-middle bg-info"
                    scope="col"
                    style={{ width: "180px" }}
                  >
                    <h6 className="text-white font-weight-bold"> Administrador</h6>
                  </th>
                  <td className="align-middle text-center" style={{ width: "280px" }}>
                    {admin.assignments.map((assignment, index) => (
                      <React.Fragment key={index}>
                        {assignment.user_name.label}
                        <br />
                      </React.Fragment>
                    ))}
                  </td>
                  <td className="align-middle text-center" style={{ width: "180px" }}>
                    <Assignments
                      assignable={admin.id}
                      action={'produce'}
                    />
                  </td>
                  <th
                    className="text-center align-middle bg-info"
                    scope="col"
                    style={{ width: "180px" }}
                  >
                    <h6 className="text-white font-weight-bold">
                      Plazo de Respuesta
                    </h6>
                  </th>
                  <td className="align-middle text-center" style={{ width: "280px" }}>
                    {admin.answer_time} días
                  </td>
                </tr>
              </thead>
            </table>
            <table className="table table-bordered table-hover table-sm table-striped">
              <thead>
                <tr>
                  <th
                    className="text-center align-middle bg-info py-2 text-nowrap"
                    style={{ width: '20%' }}
                  >
                    <h6 className="text-white font-weight-bold mb-0">URL del Laboratorio</h6>
                  </th>
                  <td className="align-middle">
                    <div className="d-flex justify-content-center align-items-center">
                      <div className="d-flex flex-column align-items-center">
                        <h5 className="text-info font-weight-bold mb-3">Código QR</h5>
                        {qrCode ? (
                          <img 
                            src={qrCode} 
                            alt="Código QR" 
                            className="img-fluid border border-info rounded shadow-sm"
                            style={{ maxWidth: '220px', maxHeight: '220px' }}
                          />
                        ) : (
                          <p className="text-muted font-italic">Cargando código QR...</p>
                        )}
                      </div>

                      <div className="d-flex flex-column align-items-center ml-5">
                        <h5 className="text-info font-weight-bold mb-3">Redirección por imagen</h5>
                        {image?.image === true ? (
                          <a 
                            href={`https://www.sigiswo.com/main_laboratories?portal_id=${admin.portal_id}&layout=pqrsf&color=true`}
                            target="_blank"
                          >
                            <img 
                              src={image.url_image} 
                              alt="Company Picture" 
                              className="img-thumbnail"
                              style={{ maxWidth: '220px', maxHeight: '220px' }}
                            />
                          </a>
                        ) : (
                          <a 
                            href={`https://www.sigiswo.com/main_laboratories?portal_id=${admin.portal_id}&layout=pqrsf&color=true`}
                            target="_blank"
                          >
                            <h1 className="text-wrap font-weight-bold">{image.url_image}</h1>
                          </a>
                        )}
                      </div>
                    </div>
                  </td>
                </tr>
              </thead>
            </table>
            <table className="table table-bordered table-hover table-sm table-striped">
              <thead>
                <tr>
                  <th
                    className="text-center align-middle bg-info py-2"
                    style={{ width: '70%' }}
                  >
                    <h6 className="text-white font-weight-bold mb-0">
                      Color elegido para el formulario de solicitud y pantalla principal:
                    </h6>
                  </th>
                  <td
                    className="align-middle text-center font-weight-bold"
                    style={{
                      backgroundColor: admin.color,
                      color: admin.color === '#ffffff' ? '#000' : '#fff',
                      padding: '10px',
                    }}
                  >
                    {admin.color ? (
                      <span>{admin.color}</span>
                    ) : (
                      <span className="text-muted font-italic">No se seleccionó color</span>
                    )}
                  </td>
                </tr>
              </thead>
            </table>
          </React.Fragment>
        ))
      ) : (
        <p>No hay datos disponibles.</p>
      )}
    </div>
  );
}

export { Table };
