import React, { useState, useEffect } from "react";
import axios from "axios";

function serviceCategoryLaboratory(){
  const [categoryLaboratory, setCategoryLaboratory] = useState([])
  const [error, setError] = useState(null)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    const getData = async () => {
      try{
        const url = `/api/v2/category_type_laboratory_tests`;
        const response = await axios.get(`${url}`)
        if (response.status === 200){
          const data = await response.data;
          setCategoryLaboratory(data)
        } else {
          throw new Error('Network response was not ok.')
        }
      } catch (error){
        setError(true)
      } finally {
        setLoading(false)
      }
    }
    getData();
    return () => {
      setError(null)
    }
  }, [])
  return {
    loading,
    error,
    categoryLaboratory
  }
}

export { serviceCategoryLaboratory }