import React, { useState } from "react";
import { InputGroup } from "../../InputGroup";
import axios from "axios";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { param } from "jquery";
import { LoadingSpinner } from "../../LoadingSpinner";
import Tooltip from "../../Tooltip";

function Status({portal, color}){
  const [code, setCode] = useState('')
  const [indentification, setIndentification] = useState('')
  const [response, setResponse] = useState([])
  const [notpresent, setNotPresent] = useState('')
  const [startDate, setStartDate] = useState("")
  const [endDate, setEndDate] = useState("")
  const [noPresentData, setNoPresentData] = useState("")
  const [loading, setLoading] = useState(false)
  const onSubmit = (e) => {
    e.preventDefault()
    const data = {
      code: code,
      indentification: indentification
    }
    findTaboratories(data)
  }

  const findTaboratories = async () =>{
    if (indentification != '' && code != ''){
      setLoading(true)
      try{
        const bauseUrl = "/api/v2/laboratories/see_laboratories_created";
        const params = new URLSearchParams();
        params.append("filters[indentification]", indentification);
        params.append("filters[code]", code);
        params.append("filters[start_date]", startDate);
        params.append("filters[end_date]", endDate);
        const url = `${bauseUrl}?${params}`;
        const response = await fetch(url)
        if (response.ok) {
          const data = await response.json();

          if (data && data.length > 0) {
            setResponse(data);
            setNoPresentData("")
            setLoading(false)
          } else {
            setResponse([])
            setNotPresent("No se encontraron registros con los datos ingresados");
            setLoading(false)
          }
        } else {
          setNotPresent("Error al obtener los datos. Inténtelo más tarde.");
          setLoading(false)
        }
      } catch(error){
        setLoading(false)
        console.log('errores')
      }
    } else {
      setNoPresentData("Debe Ingresar El código y la identificación para realizar la busqueda")
    }
  }

  const delateFilters = async () => {
    setEndDate("")
    setStartDate("")
    findTaboratories()
  }

  const type_state = (data) => {
    let name = '';
    switch(data) {
        case 'rejected':
            name = 'No aprobada';
            break;
        case 'awaiting_presentation_and_closure':
            name = 'Esperando envío de informe y cierre';
            break;
        case 'awaiting_approved':
            name = 'Esperando aprobación';
            break;
        case 'awaiting_test_approval':
            name = 'Esperando la ejecución de pruebas'
            break;
        case 'returned_aprobate_test':
            name = 'No aprobada'
            break;
        case 'closed':
            name = 'Cerrada'
            break;
        default:
            name = 'Estado desconocido';
    }
    return name;
  }
  return(
    <React.Fragment>
      <a
        href="/main_laboratories?portal_id=6011faf0-0604-40c6-a347-c2aead8e50ce&layout=pqrsf&color=true"
        className="btn btn-secondary btn-sm floating-top-left rounded-circle"
      >
        <i className="fas fa-arrow-left"/>
      </a>
      <div>
        <div className="card">
          <div className="p-4">
            <div className="card">
              <div className="card-header bg-white">
                {portal.short_name}
              </div>
              <div className="card-body p-4">
                {(noPresentData != '') && (
                  <div className="alert alert-danger" role="alert">
                    {noPresentData}
                  </div>
                )}
                <h1 className="font-weight-bold text-center">
                  {portal.short_name}
                </h1>
                <p>
                  Ingresa el número de identificación y el código para encontrar tus solicitudes de laboratorio
                </p>
                <form onSubmit={onSubmit}>
                  <InputGroup iconClass="fas fa-id-card" label="Cédula o RUC">
                    <input
                      type="text"
                      className="form-control"
                      name="Descripción Solicitud"
                      placeholder="Descripción Punto Toma de Muestra"
                      onChange={(e) => setIndentification(e.target.value)}
                    />
                  </InputGroup>
                  <InputGroup iconClass="fas fa-envelope" label="Código">
                    <input
                      type="text"
                      className="form-control"
                      name="Descripción Solicitud"
                      placeholder="Descripción Punto Toma de Muestra"
                      onChange={(e) => setCode(e.target.value)}
                    />
                  </InputGroup>
                  <div className="container-fluid px-0 d-none d-sm-block my-2">
                    <div className="row border-top border-bottom justify-content-center py-2">
                      <div className="d-flex justify-content-center align-items-center gap-3">
                        <InputGroup iconClass="fas fa-calendar" label="Solicitudes desde">
                          <DatePicker
                            selected={startDate}
                            onChange={(date) => setStartDate(date)}
                            showMonthDropdown
                            className="form-control"
                          />
                        </InputGroup>

                        <InputGroup iconClass="fas fa-calendar" label="Solicitudes hasta">
                          <DatePicker
                            selected={endDate}
                            onChange={(date) => setEndDate(date)}
                            showMonthDropdown
                            className="form-control"
                          />
                        </InputGroup>
                      </div>
                    </div>
                  </div>
                  <button
                    className="btn btn-primary btn-block"
                    type="submit"
                    style={{backgroundColor: color}}
                  >
                    Buscar
                  </button>
                  <button
                      className="btn btn-primary btn-block"
                      onClick={delateFilters}
                    >
                      Borrar Filtros
                    </button>
                </form>
              </div>
            </div>
          </div>
          <div className="card">
            {loading && <LoadingSpinner/>}
            {response.length > 0 ? (
              <div>
                <table className="table table-bordered">
                  <thead>
                    <tr>
                      <th className="text-center align-middle">
                        Código
                      </th>
                      <th className="text-center align-middle">
                        Fecha de solicitud
                      </th>
                      <th className="text-center align-middle">
                        Descripción de solicitud 
                      </th>
                      <th className="text-center align-middle">
                        Cantidad por lotes
                      </th>
                      <th className="text-center align-middle">
                        Unidades por lote
                      </th>
                      <th className="text-center align-middle">
                        Estado
                      </th>
                      <th className="text-center align-middle">
                        Pruebas seleccionadas
                      </th>
                      <th className="text-center align-middle">
                        Adjunto de resultados
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {response.map((data, index) => (
                      <tr key={index}>
                        <td>
                          {data.code}
                        </td>
                        <td>
                          {data.date}
                        </td>
                        <td>
                          {data.description_application}
                        </td>
                        <td>
                          {data.number_lots}
                        </td>
                        <td>
                          {data.units_lot}
                        </td>
                        <td>
                          { type_state(data.state) }
                        </td>
                        <td>
                          {data.request_tests.map((request, index) => (
                            <p key={index}>{request.name}</p>
                          ))}
                        </td>
                        <td>
                          { data?.file_report && !(data?.file_report_edit) && !(data?.file_report_edit_two) && (
                            <Tooltip text={"Informe Final"}>
                              <a
                                href={data.file_report}
                                className={`btn btn-outline-info btn-sm mr-2`}
                                target="_blank"                 
                              >
                                <i className="far fa-file" />
                              </a>
                            </Tooltip>
                            )
                          }
                          { data?.file_report_edit && !(data?.file_report_edit_two) && (
                            <Tooltip text={"Informe Editado"}>
                              <a
                                href={data.file_report_edit}
                                className={`btn btn-outline-info btn-sm mr-2`}
                                target="_blank"                 
                              >
                                <i className="far fa-file" />
                              </a>
                            </Tooltip>
                          )}
                          { data?.file_report_edit_two && (
                            <Tooltip text={"Informe Editado Dos"}>
                              <a
                                href={data.file_report_edit_two}
                                className={`btn btn-outline-info btn-sm mr-2`}
                                target="_blank"                 
                              >
                                <i className="far fa-file" />
                              </a>
                            </Tooltip>
                          )}
                          {data?.file_report && data?.file_report_edit && data?.file_report_edit_two ? '' : (
                            <p>
                              No se ha generado ningún informe.
                            </p>
                          ) }
                        </td>
                      </tr>
                    )
                    )}
                  </tbody>
                </table>
              </div>
            ) : (
              <p className="text-center pt-4">
                {notpresent}
              </p>
            )}
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}


export default Status