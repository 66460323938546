import axios from "axios";
import React, { useEffect, useState } from "react";

function Success ({portal, laboratory}){
  const [identificator, setIdentificator] = useState("");

  useEffect(() => {
    const fetchLogged = async() =>{
      try{
        const url = `api/v2/laboratories/find_logged_user.json?id_laboratory=${laboratory}`
        const response = await axios.get(url, {
          headers: { Accept: "aplication/json" }
        })
        if(response.status === 200){
          setIdentificator(response.data);
        }
      } catch (error){
        console.log('Error al traer el código');
      }
    }
    fetchLogged();
  }, [])

  return(
    <div className="card border-secondary shadow px-5 py-2 ml-4 mr-4 mt-4">
      <div className="card-header text-center bg-white">
        <a 
          className="btn btn-secondary btn-sm floating-top-left rounded-circle"
          href={`/main_laboratories?portal_id=${portal.id}&layout=pqrsf&color=true`}
        >
          <i className="fas fa-arrow-left"/>
        </a>
        <h3 className="text-wrap font-weight-bold text-dark">
          {portal.short_name}
        </h3>
      </div>
      <div className="card border-info shadow-sm mb-4 rounded">
        <div className="card-header bg-dark">
          <h4 className="font-weight-bold text-white text-center">
            Solcitud de Laboratorio Exitosa
          </h4>
        </div>
        <div className="card-body">
          <h4 className="text-dark text-center">
            Estimado(a), su solicitud fue recibida exitosamente.
          </h4>
          <p>
            Puede consultar el estado de su solicitud con identificación y el siguiente código:
          </p>
          <h4 className="font-weight-bold ml-3">
            Identificación: {identificator.identification}
          </h4>
          <h4 className="font-weight-bold ml-3">
            Código: {identificator.registered_code}
          </h4>
          <a
            className="btn btn-link"
            target="_blank"
            href={`/status_laboratories?portal_id=${portal.id}&layout=pqrsf&type=new&color=true`}
          > Consultar estado de Laboratorio </a>
        </div>
      </div>
    </div>
  )
}

export default Success