import React, { createContext, useState } from "react";
import { serviceCategoryLaboratory } from "./serviceCategoryLaboratory";
import axios from "axios";

const CategoryLaboratoryContext = createContext()

function CategoryLaboratoryProvider({children}){
  const{
    loading,
    error,
    categoryLaboratory
  } = serviceCategoryLaboratory()

  const [openModal, setOpenModal] = useState(false)
  const [errorResponse, setErrorResponse] = useState([])
  const [dataCategoryLaboratory, setDataCategoryLaboratory] = useState([])

  const createCategoryLaboratory = async (newCategoryLaboratory) => {
    try{
      const response = await axios.post("/api/v2/category_type_laboratory_tests", newCategoryLaboratory);
      console.log("Prueba de laboratorio creada:", response.data);
      window.location.href = "/category_type_laboratory_tests";
      setOpenModal(false);
      setErrorResponse([]);
    } catch (error){
      setErrorResponse(error.response.data.errors);
    }
  }

  const findCategoryLaboratory = async (idCatetoryLaboratory) => {
    console.log('si llegá al find')
    console.log(idCatetoryLaboratory)
    try {
      const response = await axios.get(`/api/v2/category_type_laboratory_tests/${idCatetoryLaboratory}/edit`)
      setOpenModal(true);
      console.log('response')
      console.log(response)
      setDataCategoryLaboratory(response.data)
    } catch (error){
      setErrorResponse(error.response.data.errors)
    }
  }

  const updateCategoryLaboratory = async (id, newCategoryLaboratory) => {
    try {
      const response = await axios.patch(`/api/v2/category_type_laboratory_tests/${id}`, newCategoryLaboratory)
      console.log("Categoria de Laboratorio creada con éxito", response.data)
      window.location.href = "/category_type_laboratory_tests";
      openModal(false)
      setErrorResponse([])
    } catch (error){
      setErrorResponse(error.response.data.errors)
    }
  }

  return(
    <CategoryLaboratoryContext.Provider
      value={{
        loading,
        error,
        categoryLaboratory,
        openModal,
        setOpenModal,
        createCategoryLaboratory,
        findCategoryLaboratory,
        updateCategoryLaboratory,
        errorResponse,
        dataCategoryLaboratory
      }}
    >
      {children}
    </CategoryLaboratoryContext.Provider>
  )
}

export { CategoryLaboratoryContext, CategoryLaboratoryProvider }